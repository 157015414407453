<h2>Recollect Data</h2>

<div class="form-container">

  <!-- Success message -->
  <div *ngIf="isSuccess" class="alert alert-success">
    Request accepted for recollection.
  </div>

  <form #recollectForm="ngForm">
    <!-- Dataset Dropdown -->
    <div class="form-group row">
      <label class="col-2" for="dataset">Select Dataset:</label>
      <div class="col-10">
        <select id="dataset" class="form-control" [(ngModel)]="selectedDataset" (change)="onDatasetChange()" name="dataset" required>
          <option *ngFor="let dataset of datasets" [value]="dataset">{{ dataset }}</option>
        </select>
      </div>
    </div>

    <!-- Parameter Dropdown -->
    <div class="form-group row">
      <label class="col-2" for="parameter">Parameter:</label>
      <div class="col-10">
        <select id="parameter" class="form-control" [(ngModel)]="selectedParameter" name="parameter" [disabled]="!parameters.length" required>
          <option *ngFor="let parameter of parameters" [value]="parameter">{{ parameter }}</option>
        </select>
      </div>
    </div>

    <!-- Model ID Input -->
    <div class="form-group row">
      <label class="col-2" for="parameter">Model Id:</label>
      <div class="col-10">
        <input
          type="text"
          id="modelId"
          class="form-control"
          [(ngModel)]="modelId"
          name="modelId"
          placeholder="yyyyMMddTHHmmssZ"
          pattern="\d{4}\d{2}\d{2}T\d{2}\d{2}\d{2}Z"
          required
        />
      </div>
    </div>

    <!-- Submit Button -->
    <button class="btn btn-primary" (click)="onSubmit($event)">Submit</button>
  </form>

  <!-- Confirmation Modal -->
  <div class="modal" *ngIf="isModalVisible">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <h2>Confirm operation</h2>
      <p>Please check the details of the request, and press OK to submit it.</p>
      <p><strong>Model ID:</strong> {{ modelId }}</p>
      <p><strong>Dataset:</strong> {{ selectedDataset }}</p>
      <p><strong>Parameter:</strong> {{ selectedParameter }}</p>
      <div class="modal-actions">
        <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
        <button class="btn btn-primary" (click)="confirmSubmit(recollectForm)">OK</button>
      </div>
    </div>
  </div>
</div>
