import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecollectDataService {
  private apiUrl = `${environment.base_url}/dataset/forcedetect`;
  private tenant = `maersk`;
  token: any = '';
  constructor(private tokenService: TokenService, private http: HttpClient) {}

  postRecollectData(data: any): Observable<any> {
    const token = this.tokenService.getToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Add the token here
    });

    return this.http.post(this.apiUrl, data, { headers, observe: 'response' });
  }
}
