import { Component } from '@angular/core';
import { JournalService } from '../../services/journal.service';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faFlag,
  faCheckCircle,
  faShieldAlt,
  faExchangeAlt,
  faCalendarAlt,
  faFlagCheckered,
  faCloudDownloadAlt,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-journal',
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, CommonModule, FontAwesomeModule],
  templateUrl: './journal.component.html',
  styleUrl: './journal.component.css',
})
export class JournalComponent {
  dropdownOptions = [
    'Bio',
    'ECMWF',
    'Hindcast',
    'ImportedNetCdf',
    'Manta',
    'MantaAnalytic',
    'MantaTides',
    'SvasekCurrents',
    'SPS',
    'TropicalCyclones',
  ];
  modelId: string = '';
  datasetName: string = '';
  tenant: string = 'maersk';
  items: any[] = [];
  constructor(private journalService: JournalService, library: FaIconLibrary) {
    library.addIcons(
      faFlag,
      faCheckCircle,
      faShieldAlt,
      faExchangeAlt,
      faCalendarAlt,
      faFlagCheckered,
      faCloudDownloadAlt,
      faTasks
    );
  }

  onSubmit(): void {
    this.journalService
      .sendGetRequest(this.modelId, this.datasetName, this.tenant)
      .subscribe({
        next: (response) => {
          this.items = response;
        },
        error: (error) => {
          console.error('An error occurred:', error);
        },
      });
  }
  getStatusLabel(status: number): string {
    const statusMap: { [key: number]: string } = {
      0: 'Data Available',
      1: 'Transfer Completed',
      2: 'Integrity Check Passed',
      3: 'Data Transformed',
      4: 'Forecast Finalization Scheduled',
      5: 'Forecast Finalized',
      6: 'Forecast Completed',
      7: 'Postprocessing OK',
      8: 'Postprocessing OK',
      9: 'Postprocessing OK',
    };
    return statusMap[status] || 'Unknown Status';
  }
}
