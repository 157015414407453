<h1>Data Status</h1>

<div *ngFor="let parsedData of parsedDataArray">
  <h3>{{ parsedData.dataName }}</h3>
  <!-- Display the dataName -->

  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th *ngFor="let header of parsedData.headers">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of parsedData.dataRows">
          <td *ngFor="let cell of row" [innerHTML]="formatCellContent(cell)">
            {{ cell }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
