import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule, NgForm } from '@angular/forms';
import { TokenService } from '../../services/token.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, NgIf],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  token: string = '';
  errorMessage: string | null = null;

  constructor(private tokenService: TokenService, private router: Router) {}

  onSubmit(event: Event): void {
    event.preventDefault();

    if (this.token.trim()) {
      this.tokenService.storeToken(this.token);
      this.tokenService.validateToken().subscribe((isValid) => {
        if (isValid) {
          window.location.href = '/dashboard';
        } else {
          this.errorMessage = 'Invalid credentials.';
          this.tokenService.signOut();
        }
      });
    }
  }
}
