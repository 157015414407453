import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class DataStatusService {
  private apiUrl = `${environment.base_url}/org/data/status/`;
  token: any = '';
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getData(): Observable<any> {
    this.token = this.tokenService.getToken();
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.token}`
    );
    return this.http.get(this.apiUrl, { headers });
  }
}
