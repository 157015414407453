<div class="container">
  <div class="form-container">
    <form (ngSubmit)="onSubmit()" #getForm="ngForm">
      <div class="form-group">
        <!-- Dropdown for param1 -->
        <label for="param1">Select Dataset:</label>
        <select
          id="param1"
          [(ngModel)]="datasetName"
          name="datasetName"
          required
        >
          <option *ngFor="let option of dropdownOptions" [value]="option">
            {{ option }}
          </option>
        </select>
      </div>

      <!-- Textbox for param2 (with pattern yyyyMMddTHHmmssZ) -->
      <div class="form-group">
        <label for="param2">ModelID (yyyyMMddTHHmmssZ):</label>
        <input
          type="text"
          id="param2"
          [(ngModel)]="modelId"
          name="modelId"
          required
          pattern="^\d{4}\d{2}\d{2}T\d{2}\d{2}\d{2}Z$"
          #modelIdModel="ngModel"
          placeholder="yyyyMMddTHHmmssZ"
        />
        <div *ngIf="modelIdModel.invalid && modelIdModel.touched" class="error">
          Please enter a valid date in the format yyyyMMddTHHmmssZ
        </div>
      </div>

      <div class="form-group">
        <button type="submit" [disabled]="!getForm.form.valid">Submit</button>
      </div>
    </form>
  </div>

  <div class="table-container responsive-table">
    <table>
      <thead>
        <tr>
          <th>Evidence</th>
          <th>Parameter</th>
          <th>Status</th>
          <th>Timestamp (LOCAL)</th>
          <th>Timestamp (UTC)</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items" [ngClass]="'status-' + item.status">
          <td>{{ item.evidenceName }}</td>
          <td>{{ item.parameter }}</td>
          <td>
            <span class="status-label">{{ getStatusLabel(item.status) }}</span>
          </td>
          <td>{{ item.timestamp | date : "MMMM d, y - h:mm a (z)" }}</td>
          <td>
            {{ item.timestamp | date : "MMMM d, y - HH:mm" : "UTC" }} (UTC)
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
