import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenKey = 'supporthub-token';
  private validationEndpoint = `${environment.base_url}`;

  constructor(private http: HttpClient) {}

  storeToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  validateToken(): Observable<boolean> {
    const token = this.getToken();

    if (!token) {
      return of(false);
    }

    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get(this.validationEndpoint, { headers }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  signOut(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
