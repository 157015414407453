import { Component } from '@angular/core';
import { DataStatusService } from '../../../services/data-status.service';
import { DataStatusReponse } from '../../../interfaces/DataStatusReponse';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-status',
  standalone: true,
  imports: [NgIf, NgFor],
  templateUrl: './status.component.html',
  styleUrl: './status.component.css',
})
export class StatusComponent {
  data: DataStatusReponse[] = [];
  parsedDataArray: {
    headers: string[];
    dataRows: string[][];
    dataName: string;
  }[] = [];

  constructor(private dataStatusService: DataStatusService) {}

  ngOnInit(): void {
    this.dataStatusService.getData().subscribe((result) => {
      this.data = result;
      this.parsedDataArray = this.data.map((item) => {
        const parsedData = this.parseApiResponse(item.dataStatus);
        return {
          dataName: item.dataName,
          headers: parsedData.headers,
          dataRows: parsedData.dataRows,
        };
      });
    });
  }

  // Adjusted return type and logic for the method
  parseApiResponse(response: string): {
    headers: string[];
    dataRows: string[][];
  } {
    const rows = response.trim().split('\n');

    // Extract headers from the first row after the "##" line
    const headerRow = rows[1]; // Second row contains headers
    const dataRows = rows.slice(2); // Remaining rows contain the data

    // Map headers and trim spaces
    const headers = headerRow.split('|').map((header) => header.trim());

    // Map the data rows and trim spaces
    const data = dataRows.map((row) =>
      row.split('|').map((cell) => cell.trim())
    );

    return { headers, dataRows: data };
  }
  // Helper method to format bold or colored text
  formatCellContent(cell: string): string {
    // Check for bold formatting
    if (cell.includes('**')) {
      return cell.replace(
        /\*\*(.*?)\*\*/g,
        '<b style="color: #009FDA;">$1</b>'
      ); // Maersk blue color for bold
    }

    // Add more formatting options (e.g., specific text patterns to color)
    return cell;
  }
}
