import { CanActivate, Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard implements CanActivate {
  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.tokenService.validateToken();
  }
}
