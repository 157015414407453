import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { PostprocessingService } from '../../../services/postprocessing.service';

@Component({
  selector: 'app-postprocess',
  standalone: true,
  imports: [CommonModule, FormsModule, NgIf],
  templateUrl: './postprocess.component.html',
  styleUrl: './postprocess.component.css',
})
export class PostprocessComponent {
  forecastVersion: string = '';
  postprocessingJobs: string[] = [
    'NetCdfExportSps',
    'NetCdfExportSvasek',
    'NetCdfExportMantaWave',
    'NetCdfExportMantaWavePeriod',
    'NetCdfExportMantaPeriod',
    'NetCdfMantaSwell',
    'NetCdfMantaSwellPeriod',
    'NetCdfMantaPressure',
    'NetCdfMantaHPa500',
    'NetCdfMantaWind10M',
    'NetCdfMantaWind30M',
    'NetCdfMantaWind50M',
    'NetCdfMantaWindGusts10M',
    'NetCdfMantaWindGusts50M',
    'NetCdfMantaVisibility',
    'NetCdfMantaRelativeHumidity',
    'NetCdfMantaAirTemperature',
    'NetCdfMantaDewPoint',
    'NetCdfMantaSeaWaterTemperature',
    'NetCdfMantaRiskWindSpeed',
    'NetCdfMantaPrecipitation',
    'NetCdfMantaFreezingLevel',
    'NetCdfMantaWeatherCode',
    'NetCdfMantaIcing',
    'NetCdfMantaGlobalCurrents',
    'NetCdfMantaRiskWaveHeight',
    'NetCdfMantaIceConcentration',
    'NetCdfMantaSeaWaterSalinity',
    'NetCdfMantaSeaWaterDensity',
    'NetCdfMantaSeaWaterDynamicViscosity',
    'NongridExport',
    'HotStorageWarmUp1',
    'HotStorageWarmUp2',
    'HotStorageWarmUp3',
    'HotStorageWarmUp4',
    'HotStorageWarmUp5',
    'HotStorageWarmUp6',
    'HotStorageClimaticWarmUp',
  ];
  selectedJobs: string[] = [];
  isModalVisible: boolean = false;
  isSubmissionButtonDisabled: boolean = false;
  isSuccess: boolean = false;
  isLoading: boolean = false;

  constructor(private postProcessingService: PostprocessingService) {}
  onSubmit(event: Event): void {
    event.preventDefault(); // Prevent the default form submission

    // Validate form fields
    if (this.forecastVersion) {
      // Show the confirmation modal
      this.isSubmissionButtonDisabled = false;
      this.isModalVisible = true;
    } else {
      console.log('Please fill in all required fields.');
    }
  }

  closeModal(): void {
    this.isSubmissionButtonDisabled = true;
    this.isModalVisible = false; // Close the modal without submitting
  }

  confirmSubmit(form: NgForm): void {
    this.isLoading = true;

    const formData = {
      tenant: 'maersk',
      forecast_version: this.forecastVersion,
      postprocessing_jobs: this.selectedJobs,
    };

    this.postProcessingService.postprocessData(formData).subscribe({
      next: (response) => {
        if (response.status === 202) {
          this.isSuccess = true;
          setTimeout(() => {
            this.isSuccess = false; // Hide the success message after 5 seconds
            this.forecastVersion = '';
            this.selectedJobs = [];
            this.isLoading = false;
          }, 5000);
        }
      },
      error: (error) => {
        console.error('Error occurred:', error);
      },
    });

    // Close the modal
    this.isModalVisible = false;
    this.isSubmissionButtonDisabled = false;
  }
}
