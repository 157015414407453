import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JournalService {
  private apiUrl = `${environment.base_url}/org/processingjournal`;
  private tenant = `maersk`;
  token: any = '';
  constructor(private tokenService: TokenService, private http: HttpClient) {}

  sendGetRequest(
    modelId: string,
    dataset: string,
    tenant: string
  ): Observable<any> {
    const token = this.tokenService.getToken();

    let params = new HttpParams()
      .set('modelId', modelId)
      .set('datasetName', dataset)
      .set('tenant', tenant);

    return this.http.get(`${this.apiUrl}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    });
  }
}
