<div class="token-container">
  <form (submit)="onSubmit($event)">
    <input
      type="password"
      [(ngModel)]="token"
      name="token"
      required
      placeholder="Enter token"
    />
    <button type="submit">Submit</button>
  </form>
  <div *ngIf="errorMessage">{{ errorMessage }}</div>
</div>
