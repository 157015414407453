import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';
import { HeaderComponent } from './components/shared/header/header.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { TokenService } from './services/token.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgFor, NgIf, HeaderComponent, SidebarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  isAuthenticated: boolean = false;
  tokenChecked: boolean = false;

  constructor(private tokenService: TokenService, private router: Router) {}

  ngOnInit(): void {
    this.tokenService.validateToken().subscribe((isValid) => {
      this.isAuthenticated = isValid;
      this.tokenChecked = true;

      if (!this.isAuthenticated) {
        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }
}
