<h2>Regenerate Forecast</h2>

<div class="form-container">
  <!-- Success message -->
  <div *ngIf="isSuccess" class="alert alert-success">
    The request was submitted successfully.
  </div>

  <form #regenerateForm="ngForm">
    <!-- Model ID Input -->
    <div class="form-group row">
      <div class="col-12">
        <input
          type="text"
          id="forecastVersion"
          class="form-control"
          [(ngModel)]="forecastVersion"
          name="forecastVersion"
          placeholder="Forecast Version: yyyyMMddHHmmss"
          pattern="\d{4}\d{2}\d{2}\d{2}\d{2}\d{2}"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <div>
        <label>
          <input
            type="radio"
            name="action"
            value="delete"
            [(ngModel)]="actionType"
          />
          Delete Forecast
        </label>
        <label>
          <input
            type="radio"
            name="action"
            value="reissue"
            [(ngModel)]="actionType"
          />
          Reissue Forecast
        </label>
      </div>
    </div>

    <!-- Submit Button -->
    <button class="btn btn-primary" (click)="onSubmit($event)">Submit</button>
  </form>

  <!-- Confirmation Modal -->
  <div class="modal" *ngIf="isModalVisible">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <h2>Confirm operation</h2>
      <p>Please check the details of the request, and press OK to submit it.</p>
      <p>
        <strong>{{ actionType | titlecase }} forecast data for:</strong>
        {{ forecastVersion }}
      </p>
      <div class="modal-actions">
        <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
        <button class="btn btn-primary" (click)="confirmSubmit(regenerateForm)">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
