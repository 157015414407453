import { Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TokenGuard } from './guards/token.guard';
import { LoginComponent } from './components/login/login.component';
import { StatusComponent } from './components/data/status/status.component';
import { JournalComponent } from './components/journal/journal.component';
import { RecollectComponent } from './components/data/recollect/recollect.component';
import { PostprocessComponent } from './components/data/postprocess/postprocess.component';
import { ForecastComponent } from './components/data/forecast/forecast.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'journal',
    component: JournalComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'data/status',
    component: StatusComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'data/recollect',
    component: RecollectComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'forecast',
    component: ForecastComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'postprocess',
    component: PostprocessComponent,
    canActivate: [TokenGuard],
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];
