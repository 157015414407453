<!-- app.component.html -->
<div *ngIf="tokenChecked">
  <div *ngIf="isAuthenticated; else loginPage">
    <!-- Show header, sidebar, and main content when authenticated -->
    <app-header></app-header>
    <app-sidebar class="sidebar"></app-sidebar>
    <div class="main-content">
      <router-outlet></router-outlet>  <!-- Dashboard component will be shown here -->
    </div>
  </div>

  <!-- If not authenticated, show the login page -->
  <ng-template #loginPage>
    <router-outlet></router-outlet>  <!-- LoginComponent will be shown here -->
  </ng-template>
</div>

<!-- Optional loading state while token is being checked -->
<ng-template #loading>
  <p>Loading...</p>
</ng-template>
