import { CommonModule, NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { RecollectDataService } from '../../../services/recollect-data.service';

@Component({
  selector: 'app-recollect',
  standalone: true,
  imports: [NgFor, CommonModule, FormsModule],
  templateUrl: './recollect.component.html',
  styleUrl: './recollect.component.css',
})
export class RecollectComponent {
  /**
   *
   */
  constructor(private recollectDataService: RecollectDataService) {}

  selectedDataset: string = '';
  selectedParameter: string = '';
  modelId: string = '';

  isModalVisible: boolean = false;
  isSubmissionButtonDisabled: boolean = false;
  isSuccess: boolean = false;

  datasets: string[] = [
    'Bio',
    'ECMWF',
    'Hindcast',
    'ImportedNetCdf',
    'Manta',
    'MantaAnalytic',
    'MantaTides',
    'SvasekCurrents',
    'SPS',
    'TropicalCyclones',
  ];
  parameters: string[] = [];
  allParameters: { [key: string]: string[] } = {
    Bio: ['0.25x0.25-global_mercator'],
    ECMWF: [
      'air_pressure_mean_sea_level',
      'wind_direction_10m',
      'wind_speed_10m',
    ],
    Hindcast: [
      'windwave',
      'air_pressure',
      'wind_10m',
      'wind_50m',
      'swell',
      'windwave_mean_wave_period_tmm10',
      'swell_1_of_1_mean_wave_period_tmm10',
      'wind_gust_10m',
      'wind_gust_50m',
      'risk_wind_speed_10m',
      'hpa_500',
      'precipitation',
      'air_temperature',
      'sea_temperature',
      'visibility',
      'icing',
      'weather_code',
      'global_currents',
    ],
    Manta: [
      'windwave',
      'air_pressure',
      'wind_10m',
      'wind_30m',
      'wind_50m',
      'swell',
      'windwave_mean_wave_period_tmm10',
      'swell_1_of_1_mean_wave_period_tmm10',
      'wind_gust_10m',
      'wind_gust_50m',
      'risk_wind_speed_10m',
      'hpa_500',
      'precipitation',
      'air_temperature',
      'dew_point_temperature',
      'sea_temperature',
      'visibility',
      'icing',
      'weather_code',
      'relative_humidity',
      'global_currents',
    ],
    MantaAnalytic: [
      'windwave_mean_wave_period_tm02',
      'windwave_peak_period',
      'windwave_maximum_wave_steepness',
      'windwave_mean_wave_length',
      'windwave_maximum_wave_height',
      'swell_1_of_1_mean_wave_period_tm02',
      'swell_1_of_1_peak_period',
      'swell_1_of_1_maximum_wave_steepness',
      'swell_1_of_1_mean_wave_length',
      'swell_1_of_1_maximum_wave_height',
      'windwave_negative_first_frequency_moment',
      'swell_1_of_1_negative_first_frequency_moment',
      'swell_1_of_3_negative_first_frequency_moment',
      'swell_2_of_3_negative_first_frequency_moment',
      'windwave_zeroth_frequency_moment',
      'swell_1_of_1_zeroth_frequency_moment',
      'swell_1_of_3_zeroth_frequency_moment',
      'swell_2_of_3_zeroth_frequency_moment',
      'windwave_first_frequency_moment',
      'swell_1_of_1_first_frequency_moment',
      'swell_1_of_3_first_frequency_moment',
      'swell_2_of_3_first_frequency_moment',
      'windwave_second_frequency_moment',
      'swell_1_of_1_second_frequency_moment',
      'swell_1_of_3_second_frequency_moment',
      'swell_2_of_3_second_frequency_moment',
    ],
    MantaTides: ['sea_surface_height', 'tidal_currents'],
    SPS: ['10', '100'],
    SvasekCurrents: [
      '0.002x0.001-suez_channel_svasek_sv001_suezch',
      '0.05x0.05-australian_waters_svasek_sv001_naussm',
      '0.05x0.05-east_china_sea_svasek_sv001_ecs',
      '0.05x0.05-europe_svasek_sv001_gecsm',
      '0.05x0.05-gibraltar_svasek_sv001_gibr',
      '0.05x0.05-gulf_of_suez_svasek_sv001_suezgu',
      '0.05x0.05-gulf_of_tonkin_svasek_sv001_tongu',
      '0.05x0.05-mediterranean_sea_svasek_sv001_suezmed',
      '0.05x0.05-persian_gulf_svasek_sv001_persgu',
      '0.05x0.05-south_china_sea_svasek_sv001_scsm',
      '0.05x0.05-taiwan_strait_svasek_sv001_taistr',
      '0.05x0.05-yellow_sea_svasek_sv001_ys',
      '0.05x0.05-gulf_of_finland_svasek_sv001_gufin',
      '0.05x0.05-kattegat_west_baltic_sea_svasek_sv001_wbalt',
      '0.05x0.05-south_baltic_sea_svasek_sv001_sbalt',
    ],

    ImportedNetCdf: ['archive'],
    TropicalCyclones: ['TropicalCyclones'],
  };

  onDatasetChange(): void {
    this.parameters = this.allParameters[this.selectedDataset] || [];
    this.selectedParameter = ''; // Reset parameter when dataset changes
  }

  onSubmit(event: Event): void {
    event.preventDefault(); // Prevent the default form submission

    // Validate form fields
    if (this.selectedDataset && this.selectedParameter && this.modelId) {
      // Show the confirmation modal
      this.isSubmissionButtonDisabled = false;
      this.isModalVisible = true;
    } else {
      console.log('Please fill in all required fields.');
    }
  }
  closeModal(): void {
    this.isSubmissionButtonDisabled = true;
    this.isModalVisible = false; // Close the modal without submitting
  }

  confirmSubmit(form: NgForm): void {
    // Proceed with form submission after confirmation
    const formData = {
      model_id: this.modelId,
      dataset_name: this.selectedDataset,
      parameter: this.selectedParameter,
    };

    this.recollectDataService.postRecollectData(formData).subscribe({
      next: (response) => {
        if (response.status === 202) {
          this.isSuccess = true;
          form.resetForm();
          setTimeout(() => {
            this.isSuccess = false; // Hide the success message after 5 seconds
          }, 5000);
        }
      },
      error: (error) => {
        console.error('Error occurred:', error);
      },
    });

    // Close the modal
    this.isModalVisible = false;
    this.isSubmissionButtonDisabled = false;
  }
}
