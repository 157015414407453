<aside class="sidebar">
  <ul>
    <li>
      <a
        routerLink="/dashboard"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        >Dashboard</a
      >
    </li>
    <li>
      <a
        routerLink="/journal"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        >Journal</a
      >
    </li>
    <li>
      <a
        routerLink="/data/status"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        >Data Status</a
      >
    </li>
    <li>
      <a
        routerLink="/data/recollect"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        >Recollect Data</a
      >
    </li>
    <li>
      <a
        routerLink="/forecast"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        >Regenerate Forecast</a
      >
    </li>
    <li>
      <a
        routerLink="/postprocess"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        >Restart Postprocessing</a
      >
    </li>
  </ul>
</aside>
