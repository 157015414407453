<h1>Restart Postprocessing</h1>

<div class="form-container">
  <!-- Success message -->
  <div *ngIf="isSuccess" class="alert alert-success">
    Request accepted for postprocessing.
  </div>
  <form #postprocessForm="ngForm" (ngSubmit)="onSubmit($event)">
    <div class="form-group row">
      <label class="col-2" for="forecastId">Forecast Version:</label>
      <div class="col-10">
        <input
          type="text"
          id="forecastVersion"
          class="form-control"
          [(ngModel)]="forecastVersion"
          name="forecastVersion"
          [disabled]="isLoading"
          placeholder="Forecast Version: FyyyyMMddHHmmss"
          pattern="F\d{4}\d{2}\d{2}\d{2}\d{2}\d{2}"
          required
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-2" for="postprocessingJobs">Postprocessing Jobs</label>
      <div class="col-10">
        <select
          class="form-control"
          id="postprocessingJobs"
          name="postprocessingJobs"
          [disabled]="isLoading"
          [(ngModel)]="selectedJobs"
          multiple
        >
          <option *ngFor="let job of postprocessingJobs" [value]="job">
            {{ job }}
          </option>
        </select>
      </div>
    </div>

    <button
      class="btn btn-primary"
      [disabled]="isLoading"
      (click)="onSubmit($event)"
    >
      Continue
    </button>
  </form>
  <div class="modal" *ngIf="isModalVisible">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <h2>Confirm operation</h2>
      <p>Please check the details of the request, and press OK to submit it.</p>
      <p>
        <strong>Restart Postprocessing for Forecast Version:</strong>
        {{ forecastVersion }}
      </p>
      <ul>
        <li *ngFor="let job of selectedJobs">{{ job }}</li>
      </ul>
      <div class="modal-actions">
        <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
        <button
          class="btn btn-primary"
          (click)="confirmSubmit(postprocessForm)"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>
