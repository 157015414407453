import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ForecastService } from '../../../services/forecast.service';
import { catchError, delay, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-forecast',
  standalone: true,
  imports: [NgIf, FormsModule, CommonModule],
  templateUrl: './forecast.component.html',
  styleUrl: './forecast.component.css',
})
export class ForecastComponent {
  forecastVersion: string = '';

  isModalVisible: boolean = false;
  isSubmissionButtonDisabled: boolean = false;
  isSuccess: boolean = false;
  actionType: 'delete' | 'reissue' = 'delete';
  constructor(private forecastService: ForecastService) {}

  onSubmit(event: Event): void {
    event.preventDefault(); // Prevent the default form submission

    // Validate form fields
    if (this.forecastVersion) {
      // Show the confirmation modal
      this.isSubmissionButtonDisabled = false;
      this.isModalVisible = true;
    } else {
      console.log('Please fill in the forecast version.');
    }
  }
  closeModal(): void {
    this.isSubmissionButtonDisabled = true;
    this.isModalVisible = false; // Close the modal without submitting
  }
  setActionType(action: 'delete' | 'reissue') {
    this.actionType = action;
  }
  confirmSubmit(form: NgForm): void {
    const formData = {
      tenant: 'maersk',
      forecast_version: this.forecastVersion,
      action: this.actionType, // Use the selected action type
    };

    // Close the modal
    this.isModalVisible = false;
    this.isSubmissionButtonDisabled = true; // Disable button until submission is done

    this.forecastService
      .postData(formData)
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);
          return of(null); // Handle the error gracefully
        })
      )
      .subscribe({
        next: (response) => {
          if (this.actionType === 'delete' && response.status === 202) {
            this.isSuccess = true;
            form.resetForm();
            setTimeout(() => {
              this.isSuccess = false; // Hide the success message after 5 seconds
            }, 5000);
          } else if (this.actionType === 'reissue') {
            this.isSuccess = true; // Assuming reissue also has a success message
            form.resetForm();
            setTimeout(() => {
              this.isSuccess = false; // Hide the success message after 5 seconds
            }, 5000);
          }
        },
      })
      .add(() => {
        this.isSubmissionButtonDisabled = false; // Re-enable button after completion
      });
  }
}
